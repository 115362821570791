<template>
  <svg
    viewBox="0 0 40 40"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 12C6 10.4087 6.63214 8.88258 7.75736 7.75736C8.88258 6.63214 10.4087 6 12 6H32C32.3714 6 32.7355 6.10343 33.0515 6.2987C33.3674 6.49397 33.6227 6.77336 33.7889 7.10557C33.955 7.43778 34.0253 7.80969 33.9919 8.17961C33.9586 8.54953 33.8229 8.90286 33.6 9.2L28.5 16L33.6 22.8C33.8229 23.0971 33.9586 23.4505 33.9919 23.8204C34.0253 24.1903 33.955 24.5622 33.7889 24.8944C33.6227 25.2266 33.3674 25.506 33.0515 25.7013C32.7355 25.8966 32.3714 26 32 26H12C11.4696 26 10.9609 26.2107 10.5858 26.5858C10.2107 26.9609 10 27.4696 10 28V34C10 34.5304 9.78929 35.0391 9.41421 35.4142C9.03914 35.7893 8.53043 36 8 36C7.46957 36 6.96086 35.7893 6.58579 35.4142C6.21071 35.0391 6 34.5304 6 34V12Z"
      fill="#31C48D"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FlagSolidIcon",
});
</script>
