import type { RouteLocationNormalized } from "vue-router";
import AssetsService from "@/shared/services/AssetsService";
import CompanyService from "@/shared/services/CompanyService";
import AccountGuard from "@/core/modules/account/helpers/AccountGuard";
import { ScopeType } from "@/modules/settings/enums/CollaboratorsEnum";
import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

async function getStats(to: RouteLocationNormalized) {
  to.meta.companyStats = await CompanyService.stats();
  to.meta.tiktokAdEnums = await AssetsService.tiktokAdEnums();
  to.meta.brands = await CompanyService.getBrands();
}

export default [
  {
    path: "",
    beforeEnter: [
      OnboardingGuard.withCompleteOnboarding,
      getStats,
      AccountGuard.withPermissions,
    ],
    children: [
      {
        path: "/ad-center",
        name: "AdCenter",
        component: () =>
          import("@/modules/tiktok-ad-center/views/TiktokAdCenter.vue"),
        meta: {
          title: "modules.adCenter.meta",
          scope: ScopeType.AdCenter,
        },
      },
      {
        path: "open-tiktok-url",
        name: "OpenTiktokUrl",
        meta: {
          title: "modules.adCenter.meta",
        },
        redirect: (to: RouteLocationNormalized) => {
          const url = to.query.tiktokUrl as string;
          window.open(url, "_blank");
          return { name: "AdCenter" };
        },
      },
    ],
  },
];
