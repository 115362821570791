import { ApiService } from "@/core/shared/services/ApiService";
import type {
  UgcType,
  ContentOrientation,
  Age,
  Category,
  Country,
  Ethnicity,
  Gender,
  Certification,
  State,
  ShippingCarrier,
  CampaignBundle,
  AdTiktokEnums,
  CouponResponse,
  ZipCodeValidationResponse,
  MinSalaryResponse,
  MinSalaryData,
} from "@/shared/services/AssetsService.types";
import type { DsSelectOption } from "@devsalsa/vue-core";
import type { GenericAbortSignal } from "axios";
import type { CarriersResponse } from "@/modules/job/services/JobService.types";

/**
 * @class AssetsService
 */
export default class AssetsService {
  /**
   * Return Ugc Types
   * @endpoint /common/assets/ugc-types
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @returns {Promise<UgcType>}
   */
  static getUgcTypes(): Promise<UgcType[]> {
    return ApiService.get("/common/assets/ugc-types");
  }

  /**
   * Return Certifications
   * @endpoint /common/assets/job-types
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @returns {Promise<Certification>}
   */
  static getCertifications(): Promise<Certification[]> {
    return ApiService.get("/common/assets/job-types");
  }

  /**
   * Return Content orientations
   * @endpoint /common/assets/content-format
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @returns {Promise<ContentOrientation>}
   */
  static getContentOrientations(): Promise<ContentOrientation[]> {
    return ApiService.get("/common/assets/content-format");
  }

  /**
   * Return categories
   * @endpoint /common/assets/categories
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @return Promise<Category[]>
   */
  static getCategories(): Promise<Category[]> {
    return ApiService.get("/common/assets/categories");
  }

  /**
   * Return countries
   * @endpoint /common/assets/countries
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @return Promise<Country[]>
   */
  static getCountries(): Promise<Country[]> {
    return ApiService.get("/common/assets/countries");
  }

  /**
   * Return state
   * @endpoint /common/assets/state
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @return Promise<State[]>
   */
  static getStates(countryCode: string): Promise<State[]> {
    return ApiService.get(`/common/assets/states/${countryCode}`);
  }

  /**
   * Return if a zip code is valid.
   * @endpoint /zip-code/validation
   * @httpMethod POST
   * @throws {ApiServiceError}
   * @return Promise<ZipCodeValidationResponse>
   */
  static validateZipCode(zipCode: string): Promise<ZipCodeValidationResponse> {
    return ApiService.post("/common/validate-zip-code", {
      zip_code: zipCode,
    });
  }

  /**
   * Return genders
   * @endpoint /common/assets/genders
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @return Promise<Gender[]>
   */
  static getGenders(): Promise<Gender[]> {
    return ApiService.get("/common/assets/genders");
  }

  /**
   *
   * @returns {Promise<Age[]>}
   */
  static getAges(): Promise<Age[]> {
    return ApiService.get("/common/assets/age-selection");
  }

  /**
   * Return ethnicities
   * @endpoint /common/assets/ethnicities
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @return Promise<Ethnicity[]>
   */
  static getEthnicities(): Promise<Ethnicity[]> {
    return ApiService.get("/common/assets/ethnicities");
  }

  static getShippingCarriers(): Promise<ShippingCarrier[]> {
    return ApiService.get("/common/assets/shipping-carriers");
  }

  /**
   * Return keyword suggestions
   * @endpoint /common/assets/keyword-suggest
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @return Promise<DsSelectOption[]>
   */
  static getKeywordSuggestions(keyword: string): Promise<DsSelectOption[]> {
    return ApiService.get(`/common/assets/keyword-suggest/${keyword}`);
  }

  /**
   * Return interest suggestions
   * @endpoint /tiktok/company/interest/suggest
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @return Promise<DsSelectOption[]>
   */
  static getInterestSuggestions(keyword: string): Promise<DsSelectOption[]> {
    return ApiService.get(`/company/tiktok/interest/suggest/${keyword}`);
  }

  /**
   * Return hashtag suggestions
   * @endpoint /tiktok/company/hashtag/suggest
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @return Promise<DsSelectOption[]>
   */
  static getHashtagSuggestions(keyword: string): Promise<DsSelectOption[]> {
    return ApiService.get(`/company/tiktok/hashtag/suggest/${keyword}`);
  }

  /**
   * Return campaign bundles
   * @endpoint /company/assets/flat-fee-bundles
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @return Promise<CampaignBundles[]>
   *   Deprecated by API
   */
  static getBundles(
    jobTypeId: number,
    signal: GenericAbortSignal
  ): Promise<CampaignBundle[]> {
    return ApiService.get(
      `/company/assets/flat-fee-bundles/${jobTypeId}`,
      undefined,
      signal
    );
  }

  /**
   * Return TikTok ad enums
   * @endpoint /common/assets/tt-ad-enums
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @return Promise<CampaignBundles[]>
   */
  static tiktokAdEnums(): Promise<AdTiktokEnums> {
    return ApiService.get(`/common/assets/tt-ad-enums`);
  }

  /**
   * Return bundle certifications
   * @endpoint /company/assets/flat-fee-bundles
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @returns {Promise<Certification>}
   */
  static bundleCertifications(): Promise<CampaignBundle[]> {
    return ApiService.get("/company/assets/flat-fee-bundles");
  }

  /**
   * Return coupon bundle
   * @endpoint /company/assets/flat-fee-bundles
   * @httpMethod POST
   * @throws {ApiServiceError}
   * @returns {Promise<CouponResponse>}
   */
  static bundles(jobTypeId: number, couponCode = "-"): Promise<CouponResponse> {
    return ApiService.post("/company/assets/flat-fee-bundles", {
      job_type_id: jobTypeId,
      coupon_code: couponCode,
    });
  }

  /**
   * found the possible carrier name from tracking number
   * @endpoint /job/event/company-shipped-product
   * @httpMethod PUT
   * @throws {BadRequestApiServiceError}
   */
  static getCarriersMatchingTrackingNumber(
    trackingNumber: string
  ): Promise<CarriersResponse> {
    return ApiService.post("/company/assets/detect-shipping-carrier", {
      tracking_nr: trackingNumber,
    });
  }

  static getMinSalary(data: MinSalaryData): Promise<MinSalaryResponse> {
    return ApiService.post(
      "/common/assets/recurring-contract-min-salary",
      data
    );
  }
}
