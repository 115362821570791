import AccountGuard from "@/core/modules/account/helpers/AccountGuard";
import { ScopeType } from "@/modules/settings/enums/CollaboratorsEnum";
import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

export default [
  {
    path: "/content",
    beforeEnter: [
      OnboardingGuard.withCompleteOnboarding,
      AccountGuard.withPermissions,
    ],
    children: [
      {
        path: "",
        name: "Content",
        component: () => import("@/modules/content/views/AvailableContent.vue"),
        meta: {
          title: "common.route.profile.meta",
          scope: ScopeType.Campaigns,
        },
      },
    ],
  },
];
