<template>
  <router-link
    :to="to"
    class="sidebar-settings-item group"
    :class="[isSelected ? 'text-gray-900' : customClass]"
  >
    <component
      :is="icon"
      class="settings-icon"
      :class="[isSelected ? 'fill-gray-900' : iconClass]"
    />
    <span v-if="!hasSlotTitle" class="settings-label">
      {{ title }}
    </span>
    <slot v-else :name="idSlot" />
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BanknotesSolidIcon from "@/shared/components/icon/BanknotesSolidIcon.vue";
import BellSolidIcon from "@/shared/components/icon/BellSolidIcon.vue";
import BriefcaseSolidIcon from "@/shared/components/icon/BriefcaseSolidIcon.vue";
import CardSolidIcon from "@/shared/components/icon/CardSolidIcon.vue";
import ColorSwatchIcon from "@/shared/components/icon/ColorSwatchIcon.vue";
import MoneySolidIcon from "@/shared/components/icon/MoneySolidIcon.vue";
import ReferralSolidIcon from "@/shared/components/icon/ReferralSolidIcon.vue";
import SubscriptionSolidIcon from "@/shared/components/icon/SubscriptionSolidIcon.vue";
import UserSolidIcon from "@/shared/components/icon/UserSolidIcon.vue";
import UsersSolidIcon from "@/shared/components/icon/UsersSolidIcon.vue";
import WalletSolidIcon from "@/shared/components/icon/WalletSolidIcon.vue";

export default defineComponent({
  name: "SidebarSettingsItem",
  components: {
    UserSolidIcon,
    MoneySolidIcon,
    BanknotesSolidIcon,
    WalletSolidIcon,
    CardSolidIcon,
    BriefcaseSolidIcon,
    BellSolidIcon,
    ReferralSolidIcon,
    SubscriptionSolidIcon,
    ColorSwatchIcon,
    UsersSolidIcon,
  },
  props: {
    to: {
      type: Object,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
      default: "fill-gray-400",
    },
    customClass: {
      type: String,
      default: "text-gray-500",
    },
  },
  data() {
    return {
      idSlot: "title",
    };
  },
  computed: {
    isSelected(): boolean {
      const isRouteChild =
        !!this.$route.meta.breadcrumb &&
        this.$route.meta.breadcrumb === this.routeName;

      return this.$route.name === this.routeName || isRouteChild;
    },
    hasSlotTitle(): boolean {
      return Reflect.has(this.$slots, this.idSlot);
    },
  },
});
</script>

<style lang="scss" scoped>
.sidebar-settings-item {
  @apply flex items-center p-1 text-sm font-medium rounded-md transition-colors duration-100;

  .settings-icon {
    @apply h-5 w-5 block mr-4 shrink-0 group-hover:fill-gray-900;
  }

  .settings-label {
    @apply flex-1 group-hover:text-gray-900;
  }
}
</style>
