<template>
  <div class="authenticated-layout-header">
    <OffCanvas
      v-model:is-open="showMenu"
      :items="contextMenuItems"
      :items-account="accountMenuItems"
    />
    <NotificationsSidebar v-model:show="showNotifications" />
    <AuthenticatedNavbar
      v-model:off-canvas-value="showMenu"
      v-model:notification-value="showNotifications"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, markRaw } from "vue";

import { DsBadge, DsModalHandler } from "@devsalsa/vue-core";

import AuthenticatedNavbar from "@/core/shared/components/Navigation/AuthenticatedNavbar.vue";
import OffCanvas from "@/core/shared/components/Navigation/OffCanvas.vue";

import NotificationsSidebar from "@/modules/notification/components/NotificationsSidebar.vue";

import type { NavigationItem } from "@/core/shared/components/Navigation/Navigation.types";

import {
  PermissionType,
  ScopeType,
} from "@/modules/settings/enums/CollaboratorsEnum";

import CollaboratorHelper from "@/shared/helpers/CollaboratorHelper";

export default defineComponent({
  name: "AuthenticatedLayoutHeader",
  components: {
    AuthenticatedNavbar,
    OffCanvas,
    NotificationsSidebar,
  },
  data() {
    return {
      showMenu: false,
      showNotifications: false,
      contextMenuItems: [] as NavigationItem[],
      accountMenuItems: [] as NavigationItem[],
    };
  },
  created() {
    this.setContextMenu();
    this.setAccountMenuItems();
  },
  methods: {
    setAccountMenuItems() {
      this.accountMenuItems.push({
        label: this.$t("core.shared.components.layout.navbar.account"),
        route: {
          name: "Account",
        },
      });

      if (
        CollaboratorHelper.checkPermission(ScopeType.MySubscription, [
          PermissionType.Full,
          PermissionType.View,
        ])
      ) {
        this.accountMenuItems.push({
          label: this.$t("core.shared.components.layout.navbar.subscription"),
          route: {
            name: "Subscription",
          },
        });
      }

      if (
        CollaboratorHelper.checkPermission(ScopeType.Wallet, [
          PermissionType.Full,
          PermissionType.View,
        ])
      ) {
        this.accountMenuItems.push({
          label: this.$t("core.shared.components.layout.navbar.wallet"),
          route: {
            name: "Wallet",
          },
        });
      }

      if (
        CollaboratorHelper.checkPermission(ScopeType.Billing, [
          PermissionType.Full,
          PermissionType.View,
        ])
      ) {
        this.accountMenuItems.push({
          label: this.$t("core.shared.components.layout.navbar.billing"),
          route: {
            name: "Billing",
          },
        });
      }

      if (
        CollaboratorHelper.checkPermission(ScopeType.PaymentMethods, [
          PermissionType.Full,
          PermissionType.View,
        ])
      ) {
        this.accountMenuItems.push({
          label: this.$t("core.shared.components.layout.navbar.paymentMethods"),
          route: {
            name: "PaymentMethods",
          },
        });
      }

      if (
        CollaboratorHelper.checkPermission(ScopeType.Brands, [
          PermissionType.Full,
          PermissionType.View,
        ])
      ) {
        this.accountMenuItems.push({
          label: this.$t("core.shared.components.layout.navbar.brands"),
          route: {
            name: "Brands",
          },
        });
      }

      if (
        CollaboratorHelper.checkPermission(ScopeType.Collaborators, [
          PermissionType.Full,
          PermissionType.View,
        ])
      ) {
        this.accountMenuItems.push({
          label: this.$t("core.shared.components.layout.navbar.collaborators"),
          route: {
            name: "Collaborators",
          },
        });
      }

      if (
        CollaboratorHelper.checkPermission(ScopeType.WhiteLabeling, [
          PermissionType.Full,
          PermissionType.View,
        ])
      ) {
        this.accountMenuItems.push({
          label: this.$t("core.shared.components.layout.navbar.whiteLabeling"),
          route: {
            name: "WhiteLabeling",
          },
        });
      }

      this.accountMenuItems.push({
        label: this.$t("core.shared.components.layout.navbar.notifications"),
        route: {
          name: "NotificationSettings",
        },
      });

      this.accountMenuItems.push({
        label: this.$t("core.shared.components.layout.navbar.requestAFeature"),
        route: {
          name: "RequestAFeature",
        },
      });

      this.accountMenuItems.push({
        label: this.$t("core.shared.components.layout.navbar.contact"),
        route: {},
        action: () => this.onOpenModal(),
      });

      if (
        CollaboratorHelper.checkPermission(ScopeType.AffiliateProgram, [
          PermissionType.Full,
          PermissionType.View,
        ])
      ) {
        this.accountMenuItems.push({
          label: this.$t("core.shared.components.layout.navbar.affiliate"),
          route: {
            name: "AffiliateProgram",
          },
        });
      }
    },
    setContextMenu() {
      this.contextMenuItems = [
        {
          label: this.$t("core.shared.components.layout.navbar.dashboard"),
          route: {
            name: "Dashboard",
          },
        },
      ];
      if (
        CollaboratorHelper.checkPermission(ScopeType.Campaigns, [
          PermissionType.Full,
          PermissionType.View,
        ])
      ) {
        this.contextMenuItems.push({
          label: this.$t("core.shared.components.layout.navbar.campaigns"),
          route: {
            name: "Campaigns",
          },
        });
      }

      if (
        CollaboratorHelper.checkPermission(ScopeType.RecurringCampaigns, [
          PermissionType.Full,
          PermissionType.View,
        ])
      ) {
        this.contextMenuItems.push({
          label: this.$t(
            "core.shared.components.layout.navbar.recurringCampaigns"
          ),
          route: {
            name: "RecurringCampaigns",
          },
          suffix: markRaw({
            name: DsBadge,
            attributes: {
              title: this.$t("common.label.new"),
              themeColor: "pink",
              size: "xs",
              class: "px-1",
              dataTestid: "beta-badge",
            },
          }),
        });
      }

      if (
        CollaboratorHelper.checkPermission(ScopeType.Campaigns, [
          PermissionType.Full,
          PermissionType.View,
        ])
      ) {
        this.contextMenuItems.push({
          label: this.$t("core.shared.components.layout.navbar.jobs"),
          route: {
            name: "Jobs",
          },
        });
      }

      if (
        CollaboratorHelper.checkPermission(ScopeType.AdCenter, [
          PermissionType.Full,
          PermissionType.View,
        ])
      ) {
        this.contextMenuItems.push({
          label: this.$t("core.shared.components.layout.navbar.adCenter"),
          route: {
            name: "AdCenter",
          },
        });
      }

      if (
        CollaboratorHelper.checkPermission(ScopeType.Products, [
          PermissionType.Full,
          PermissionType.View,
        ])
      ) {
        this.contextMenuItems.push({
          label: this.$t("core.shared.components.layout.navbar.products"),
          route: {
            name: "Products",
          },
        });
      }

      if (
        CollaboratorHelper.checkPermission(ScopeType.Content, [
          PermissionType.Full,
          PermissionType.View,
        ])
      ) {
        this.contextMenuItems.push({
          label: this.$t("core.shared.components.layout.navbar.content"),
          route: {
            name: "Content",
          },
        });
      }
    },
    onOpenModal() {
      DsModalHandler.open("contact-support-modal");
    },
  },
});
</script>
