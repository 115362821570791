<template>
  <!-- No root element so the DsToast container style can be applied -->
  <ArrowPathSolidIcon class="web-socket-reconnection-error-toast-icon" />
  <div class="web-socket-reconnection-error-toast-content">
    <h6 class="web-socket-reconnection-error-toast-title">
      {{ title }}
    </h6>
    <p class="web-socket-reconnection-error-toast-message">
      {{ message }}
    </p>
    <div>
      <DsButton @click="onClick" class="mt-2">
        {{ $t("common.button.reload") }}
      </DsButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { DsButton } from "@devsalsa/vue-core";

import ArrowPathSolidIcon from "@/shared/components/icon/ArrowPathSolidIcon.vue";

defineOptions({
  name: "WebSocketReconnectionErrorToast",
});

const { t } = useI18n();

const title = computed((): string => {
  return t("common.error.global.websocketReconnectionFailed.title");
});

const message = computed((): string => {
  return t("common.error.global.websocketReconnectionFailed.message");
});

function onClick() {
  window.location.reload();
}
</script>

<style lang="scss" scoped>
.web-socket-reconnection-error-toast {
  &-icon {
    @apply flex-none w-5 h-5;
  }

  &-content {
    @apply col-span-2 space-y-1 flex flex-col items-start w-[300px] md:w-[320px] break-words line-clamp-6;
  }

  &-title {
    @apply text-sm font-medium text-gray-900 w-full;
  }

  &-message {
    @apply text-sm font-normal text-gray-500 w-full;
  }
}
</style>
