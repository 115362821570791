import type { App } from "vue";
import StringHandler from "@/core/shared/helpers/StringHandler";

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    /**
     * @remarks
     * Helper class that contains methods for string manipulation.
     *@methods getLength(str: string)
     */
    $string: typeof StringHandler;
  }
}

export default {
  install(app: App) {
    // configure the app
    app.config.globalProperties.$string = StringHandler;
  },
};
