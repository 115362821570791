<template>
  <svg
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 2.00018C3.46957 2.00018 2.96086 2.2109 2.58579 2.58597C2.21071 2.96104 2 3.46975 2 4.00018V15.0002C2 15.7958 2.31607 16.5589 2.87868 17.1215C3.44129 17.6841 4.20435 18.0002 5 18.0002C5.79565 18.0002 6.55871 17.6841 7.12132 17.1215C7.68393 16.5589 8 15.7958 8 15.0002V4.00018C8 3.46975 7.78929 2.96104 7.41421 2.58597C7.03914 2.2109 6.53043 2.00018 6 2.00018H4ZM5 16.0002C5.26522 16.0002 5.51957 15.8948 5.70711 15.7073C5.89464 15.5198 6 15.2654 6 15.0002C6 14.735 5.89464 14.4806 5.70711 14.2931C5.51957 14.1055 5.26522 14.0002 5 14.0002C4.73478 14.0002 4.48043 14.1055 4.29289 14.2931C4.10536 14.4806 4 14.735 4 15.0002C4 15.2654 4.10536 15.5198 4.29289 15.7073C4.48043 15.8948 4.73478 16.0002 5 16.0002ZM10 14.2432L14.9 9.34318C15.2749 8.96813 15.4856 8.45951 15.4856 7.92918C15.4856 7.39885 15.2749 6.89024 14.9 6.51518L13.485 5.10018C13.1099 4.72524 12.6013 4.51461 12.071 4.51461C11.5407 4.51461 11.0321 4.72524 10.657 5.10018L10 5.75718V14.2432ZM16 18.0002H9.071L15.071 12.0002H16C16.5304 12.0002 17.0391 12.2109 17.4142 12.586C17.7893 12.961 18 13.4698 18 14.0002V16.0002C18 16.5306 17.7893 17.0393 17.4142 17.4144C17.0391 17.7895 16.5304 18.0002 16 18.0002Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ColorSwatchIcon",
});
</script>
