export default class StringHandler {
  static getLength(str: string): number {
    return str ? str.replace(/<[^>]*>/g, "").trim().length : 0;
  }

  static toCamelCase(str: string): string {
    return str
      .toLowerCase()
      .split("_")
      .map((str: string, key: number) =>
        !key ? str : str.charAt(0).toUpperCase() + str.slice(1)
      )
      .join("");
  }

  static replaceSpace(str: string): string {
    return str.replace(/\s/g, "%20");
  }
}
