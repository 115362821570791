<template>
  <header class="unauthenticated-header">
    <a class="flex items-center" href="https://joinbrands.com/" target="_self">
      <JoinBrandsLogo class="w-[33px] h-11 flex-shrink-0 xs:hidden block" />
      <LogoWithText class="h-auto w-32 flex-shrink-0 xs:block hidden" />
    </a>
    <div class="flex items-center">
      <template v-if="isImpersonated">
        <DsBadge
          :title="$t('common.label.admin', { id: adminId })"
          size="xs"
          class="mr-3"
          data-testid="admin-impersonated-badge"
        />
        <DsBadge
          v-if="isSuspended"
          :title="$t('common.label.suspended')"
          theme-color="red"
          size="xs"
          class="mr-3"
          data-testid="user-suspended-badge"
        />
      </template>
      <div class="flex space-x-6" v-if="showRegisterLinks">
        <DsExternalLink
          class="header-link"
          theme-color="pink"
          :href="creatorRegisterUrl"
        >
          {{ $t("core.modules.account.register.links.registerCreator") }}
        </DsExternalLink>
        <span class="text-jb-gray-500 text-sm sm:block hidden">
          {{ $t("core.modules.account.register.links.account") }}
        </span>
        <DsLink
          :to="{ name: 'Login' }"
          data-testid="register-login"
          class="header-link text-jb-gray-900"
        >
          {{ $t("core.modules.account.register.links.signIn") }}
        </DsLink>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import JoinBrandsLogo from "@/shared/components/icon/join-brands/JoinBrandsLogo.vue";
import LogoWithText from "@/shared/components/icon/join-brands/LogoWithText.vue";

import { DsBadge, DsExternalLink, DsLink } from "@devsalsa/vue-core";

import type { AccountInfo } from "@/core/modules/account/services/AccountService.types";

import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";

export default defineComponent({
  name: "UnauthenticatedNavbar",
  components: {
    LogoWithText,
    DsExternalLink,
    DsLink,
    JoinBrandsLogo,
    DsBadge,
  },
  computed: {
    accountInfo() {
      return AccountHandler.accountInfo.value as AccountInfo;
    },
    showRegisterLinks(): boolean {
      return this.$route.path.startsWith("/register");
    },
    creatorRegisterUrl(): string {
      return import.meta.env.VITE_APP_CREATOR_URL + "/register";
    },
    isSuspended(): boolean {
      return this.accountInfo.suspended;
    },
    adminId(): number {
      return this.accountInfo && this.accountInfo.admin_id
        ? this.accountInfo.admin_id
        : 0;
    },
    isImpersonated(): boolean {
      return this.adminId > 0;
    },
  },
});
</script>

<style lang="scss">
.unauthenticated-header {
  @apply flex items-center justify-between fixed z-10 top-0 bg-white border-b h-16 xs:px-6 px-4 w-full;

  .header-link {
    @apply font-semibold text-sm hover:underline no-underline;
  }
}
</style>
