<template>
  <div v-if="enabled" class="navigation-loader">
    <div class="navigation-loader-progress" />
  </div>
</template>

<script setup lang="ts">
/**
 * Navigation loader component
 * @version 2.0.0 - 2024-02-13
 */
import { ref } from "vue";
import { useLoaderStore } from "@/store/LoaderStore";

defineOptions({
  name: "NavigationLoader",
});

const enabled = ref(false);

useLoaderStore().$subscribe((mutation, state) => {
  enabled.value = state.enabled;
});
</script>

<style lang="scss" scoped>
.navigation-loader {
  @apply absolute top-16 left-0 w-full h-0.5 overflow-hidden;

  .navigation-loader-progress {
    @apply bg-gradient-to-l from-jb-pink-500 to-white rounded-full relative h-0.5 w-2/3;
    animation: infinity 1s linear infinite;
  }
}

@keyframes infinity {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
</style>
