<template>
  <header class="authenticated-navbar" data-testid="authenticated-navbar">
    <!-- Logo-->
    <div class="authenticated-navbar-container">
      <DsLink
        class="authenticated-navbar-link-logo"
        :to="{ name: 'Dashboard' }"
      >
        <JoinBrandsLogo class="authenticated-navbar-logo" />
      </DsLink>
      <!-- Links navbar lg-->
      <div v-if="$grid('hidden lg:block')" class="authenticated-navbar-desktop">
        <NavbarItem
          :to="{ name: 'Dashboard' }"
          route-name="Dashboard"
          :text="$t('core.shared.components.layout.navbar.dashboard')"
        />
        <NavbarItem
          v-permission="[{ scope: 'campaigns', type: ['full', 'view'] }]"
          :to="{ name: 'Campaigns' }"
          route-name="Campaigns"
          :text="$t('core.shared.components.layout.navbar.campaigns')"
        />
        <NavbarItem
          v-permission="[
            { scope: 'recurring_campaigns', type: ['full', 'view'] },
          ]"
          :to="{ name: 'RecurringCampaigns' }"
          route-name="RecurringCampaigns"
          :text="$t('core.shared.components.layout.navbar.recurringCampaigns')"
        >
          <template #navbar-item-suffix>
            <DsBadge
              :title="$t('common.label.new')"
              themeColor="pink"
              size="xs"
              class="recurring-campaigns-new-badge"
              dataTestid="new-badge"
            />
          </template>
        </NavbarItem>
        <NavbarItem
          v-permission="[{ scope: 'campaigns', type: ['full', 'view'] }]"
          :to="{ name: 'Jobs' }"
          route-name="Jobs"
          :text="$t('core.shared.components.layout.navbar.jobs')"
        />
        <NavbarItem
          v-permission="[{ scope: 'content', type: ['full', 'view'] }]"
          :to="{ name: 'Content' }"
          route-name="Content"
          :text="$t('core.shared.components.layout.navbar.content')"
        />
        <DsDropdown
          v-permission="[
            { scope: 'recurring_campaigns', type: ['full', 'view'] },
          ]"
          :items="moreOptionsLinks"
          :z-index="30"
          @opened="onOpenedMoreOptions"
          @closed="onClosedMoreOptions"
          class="more-options"
          size="lg"
        >
          <template #button>
            <NavbarItem
              route-name="More"
              :link="false"
              :class="[
                { 'text-gray-900': showMoreOptions || highlightMoreOptions },
              ]"
            >
              <template #navbar-item>
                <div class="authenticated-navbar-more">
                  <span>
                    {{ $t("core.shared.components.layout.navbar.more") }}
                  </span>
                  <DsChevronDownSolidIcon
                    :class="[
                      'authenticated-navbar-more__icon',
                      {
                        '-rotate-180': showMoreOptions,
                      },
                    ]"
                  />
                </div>
              </template>
            </NavbarItem>
          </template>
        </DsDropdown>
      </div>
      <!--		Avatar & notification icons-->
      <div class="ml-auto flex items-center">
        <template v-if="isImpersonated">
          <DsBadge
            :title="adminBadgeLabel"
            size="xs"
            class="mr-2"
            data-testid="admin-impersonated-badge"
          />
          <DsBadge
            v-if="isSuspended"
            :title="$t('common.label.suspended')"
            theme-color="red"
            size="xs"
            class="mr-2"
            data-testid="user-suspended-badge"
          />
        </template>
        <NavbarItem
          v-permission="[{ scope: 'chat', type: ['full', 'view'] }]"
          route-name="TheChat"
          :to="{
            name: 'TheChat',
            params: { chatId: $route.params.chatId },
          }"
          icon="MessagesSolidIcon"
          class="chat-icon"
          data-testid="chat-icon"
        >
          <template #navbar-item>
            <PointSolidIcon
              v-if="hasNewChats"
              class="chat-dot-icon"
              data-testid="new-chats-dot-icon"
            />
          </template>
        </NavbarItem>
        <NavbarItem
          route-name="Notifications"
          @click="onClickShowNotifications"
          icon="AppNotificationSolidIcon"
          class="notification-icon"
          :link="false"
          data-testid="app-notifications-icon"
        >
          <template #navbar-item>
            <PointSolidIcon
              v-if="hasNotifications"
              class="notification-dot-icon"
              data-testid="app-notifications-dot-icon"
            />
          </template>
        </NavbarItem>
        <div v-if="$grid('lg:hidden')" class="flex items-center">
          <button @click="showMenu = !showMenu">
            <MenuOutlineIcon class="h-8 w-8 text-gray-900" />
          </button>
        </div>
        <div v-if="$grid('hidden lg:block')">
          <DsDropdown :items="avatarLinks" close-on-mouse-leave :z-index="30">
            <template #button>
              <DsAvatar
                :alt="fullName"
                size="sm"
                data-testid="avatar-dropdown-menu"
              />
            </template>
          </DsDropdown>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import JoinBrandsLogo from "@/shared/components/icon/join-brands/JoinBrandsLogo.vue";
import { useAppNotifierStore } from "@/store/AppNotifierStore";
import { useChatStore } from "@/store/ChatStore";
import { useI18n } from "vue-i18n";
import { type RouteRecordNormalized, useRoute } from "vue-router";

import type { DsDropdownItem } from "@devsalsa/vue-core";
import {
  DsAvatar,
  DsBadge,
  DsChevronDownSolidIcon,
  DsDropdown,
  DsLink,
  DsModalHandler,
} from "@devsalsa/vue-core";

import NavbarItem from "@/core/shared/components/Navigation/NavbarItem.vue";

import AvatarCollaborator from "@/modules/settings/components/collaborators/AvatarCollaborator.vue";

import MenuOutlineIcon from "@/shared/components/icon/MenuOutlineIcon.vue";
import PointSolidIcon from "@/shared/components/icon/PointSolidIcon.vue";

import type { AccountInfo } from "@/core/modules/account/services/AccountService.types";

import {
  PermissionType,
  ScopeType,
} from "@/modules/settings/enums/CollaboratorsEnum";

import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";
import CollaboratorHelper from "@/shared/helpers/CollaboratorHelper";

defineOptions({
  name: "AuthenticatedNavbar",
});

const emit = defineEmits<{
  "update:offCanvasValue": [state: boolean];
  "update:notificationValue": [state: boolean];
}>();

const props = defineProps({
  offCanvasValue: {
    required: true,
    type: Boolean,
  },
  notificationValue: {
    required: true,
    type: Boolean,
  },
});

const { t } = useI18n();
const route = useRoute();
const showMoreOptions = ref(false);

const showMenu = computed({
  get(): boolean {
    return props.offCanvasValue;
  },
  set(val: boolean) {
    emit("update:offCanvasValue", val);
  },
});
const showNotifications = computed({
  get(): boolean {
    return props.notificationValue;
  },
  set(state: boolean) {
    emit("update:notificationValue", state);
  },
});
const accountInfo = computed((): AccountInfo => {
  return AccountHandler.accountInfo.value as AccountInfo;
});
const fullName = computed((): string => {
  return `${accountInfo.value.first_name || ""} ${
    accountInfo.value.last_name || ""
  }`;
});
const hasNotifications = computed((): boolean => {
  return useAppNotifierStore().unreadNotifications > 0;
});
const hasNewChats = computed((): boolean => {
  return useChatStore().unreadChats > 0;
});
const isSuspended = computed((): boolean => {
  return accountInfo.value.suspended;
});
const adminId = computed((): number => {
  return accountInfo.value.admin_id ?? 0;
});
const adminBadgeLabel = computed((): string => {
  return t("common.label.admin", { id: adminId.value });
});
const isImpersonated = computed((): boolean => {
  return adminId.value > 0;
});
const avatarLinks = computed((): DsDropdownItem[] => {
  const items = [] as DsDropdownItem[];
  if (accountInfo.value.is_collaborator) {
    items.push({
      label: {
        name: AvatarCollaborator,
        attributes: {
          fullName: fullName.value,
          brandName: accountInfo.value.collaborator.allowed_brands[0].name,
        },
      },
      cssClasses: "border-b border-gray-100 cursor-auto",
    });
  }

  items.push({
    label: t("core.shared.components.layout.navbar.account"),
    route: {
      name: "Account",
    },
  });

  if (
    CollaboratorHelper.checkPermission(ScopeType.MySubscription, [
      PermissionType.Full,
      PermissionType.View,
    ])
  ) {
    items.push({
      label: t("core.shared.components.layout.navbar.subscription"),
      route: {
        name: "Subscription",
      },
    });
  }

  if (
    CollaboratorHelper.checkPermission(ScopeType.Wallet, [
      PermissionType.Full,
      PermissionType.View,
    ])
  ) {
    items.push({
      label: t("core.shared.components.layout.navbar.wallet"),
      route: {
        name: "Wallet",
      },
    });
  }

  if (
    CollaboratorHelper.checkPermission(ScopeType.Billing, [
      PermissionType.Full,
      PermissionType.View,
    ])
  ) {
    items.push({
      label: t("core.shared.components.layout.navbar.billing"),
      route: {
        name: "Billing",
      },
    });
  }

  if (
    CollaboratorHelper.checkPermission(ScopeType.PaymentMethods, [
      PermissionType.Full,
      PermissionType.View,
    ])
  ) {
    items.push({
      label: t("core.shared.components.layout.navbar.paymentMethods"),
      route: {
        name: "PaymentMethods",
      },
    });
  }

  if (
    CollaboratorHelper.checkPermission(ScopeType.Brands, [
      PermissionType.Full,
      PermissionType.View,
    ])
  ) {
    items.push({
      label: t("core.shared.components.layout.navbar.brands"),
      route: {
        name: "Brands",
      },
    });
  }

  if (
    CollaboratorHelper.checkPermission(ScopeType.Collaborators, [
      PermissionType.Full,
      PermissionType.View,
    ])
  ) {
    items.push({
      label: t("core.shared.components.layout.navbar.collaborators"),
      route: {
        name: "Collaborators",
      },
    });
  }

  if (
    CollaboratorHelper.checkPermission(ScopeType.WhiteLabeling, [
      PermissionType.Full,
      PermissionType.View,
    ])
  ) {
    items.push({
      label: t("core.shared.components.layout.navbar.whiteLabeling"),
      route: {
        name: "WhiteLabeling",
      },
    });
  }

  items.push({
    label: t("core.shared.components.layout.navbar.notifications"),
    route: {
      name: "NotificationSettings",
    },
  });

  items.push({
    label: t("core.shared.components.layout.navbar.requestAFeature"),
    action: () => goToFeatureRequestPage(),
  });

  items.push({
    label: t("core.shared.components.layout.navbar.contact"),
    action: () => onOpenModal(),
    dataTestid: "contact-support-avatar-menu-link",
  });

  if (
    CollaboratorHelper.checkPermission(ScopeType.AffiliateProgram, [
      PermissionType.Full,
      PermissionType.View,
    ])
  ) {
    items.push({
      label: t("core.shared.components.layout.navbar.affiliate"),
      route: {
        name: "AffiliateProgram",
      },
    });
  }

  items.push({
    label: t("common.route.logout.link"),
    route: {
      name: "Logout",
    },
    cssClasses: "border-t border-gray-100",
  });
  return items;
});
const moreOptionsLinks = computed((): DsDropdownItem[] => {
  const items = [] as DsDropdownItem[];
  items.push(
    {
      label: t("core.shared.components.layout.navbar.adCenter"),
      route: {
        name: "AdCenter",
      },
      cssClasses: route.name === "AdCenter" ? "!text-gray-900" : "",
    },
    {
      label: t("core.shared.components.layout.navbar.products"),
      route: {
        name: "Products",
      },
      cssClasses: route.name === "Products" ? "!text-gray-900" : "",
    }
  );

  //TODO enable contracts
  // items.push({
  //   label: t("core.shared.components.layout.navbar.contracts"),
  //   route: {
  //     name: "Contracts",
  //   },
  //   cssClasses: route.name === "Contracts" ? "!text-gray-900" : "",
  // });

  return items;
});
const highlightMoreOptions = computed((): boolean => {
  const links = moreOptionsLinks.value.map((option): string => {
    return String((option.route as RouteRecordNormalized).name);
  });
  return links.includes(String(route.name));
});

function onClickShowNotifications(): void {
  showNotifications.value = true;
}
function goToFeatureRequestPage(): void {
  const link = document.createElement("a");
  link.href = "https://joinbrands.canny.io/feature-requests";
  link.target = "_blank";
  link.click();
}
function onOpenModal(): void {
  DsModalHandler.open("contact-support-modal");
}
function onOpenedMoreOptions(state: boolean): void {
  showMoreOptions.value = state;
}
function onClosedMoreOptions(state: boolean): void {
  showMoreOptions.value = state;
}
</script>

<style lang="scss" scoped>
.authenticated-navbar {
  @apply w-full h-16 bg-white border-b border-gray-200;

  &-container {
    @apply xl:container px-3 md:px-4 xl:px-6 flex xl:mx-auto;
  }

  &-desktop {
    @apply flex items-center justify-center mx-8 gap-8;

    :deep(.ds-badge) {
      @apply ml-2 px-1 rounded-full;
    }
  }

  &-link-logo {
    @apply flex items-center flex-none w-[33px] h-16;
  }

  &-logo {
    @apply w-[33px] h-11;
  }

  &-more {
    @apply flex items-center gap-1;

    &__icon {
      @apply w-5 h-5 transition-all;
    }
  }

  .notification-icon {
    @apply relative w-8 h-8 sm:mr-4 mr-1 flex justify-center items-center;
  }

  .chat-icon {
    @apply relative w-8 h-8 sm:mr-3 flex justify-center pt-[7px];
  }

  .notification-dot-icon {
    @apply w-3 h-3 absolute right-0 top-0 fill-jb-pink-500 border-2 border-white rounded-full;
  }

  .chat-dot-icon {
    @apply w-3 h-3 absolute right-0 top-0 fill-jb-pink-500 border-2 border-white rounded-full;
  }

  .ad-center-badge :deep(.ds-badge-title) {
    @apply py-0 px-1 rounded-md;
  }
}
</style>
