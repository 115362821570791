<template>
  <DsSidebar v-model="isShowing" width-css-class="mobile-menu-sidebar">
    <template #body>
      <div class="mobile-menu-sidebar-body">
        <div class="mobile-menu-sidebar-header">
          <div class="flex items-start justify-between">
            <DsAvatar :alt="fullName" size="md" />
            <div class="ml-3 flex h-7 items-center">
              <button
                class="cursor-pointer text-gray-900 h-8 w-8"
                @click="isShowing = !isShowing"
              >
                <CloseOutlineIcon />
              </button>
            </div>
          </div>
          <p class="text-lg font-medium text-gray-900 pt-2 truncate">
            {{ fullName }}
          </p>
          <p class="text-gray-500 text-sm font-normal">
            {{ userLabel }}
          </p>
        </div>
        <div class="relative my-6 flex-1 overflow-y-auto">
          <ul>
            <li
              v-for="(item, key) in items"
              :key="key"
              class="space-y-2 flex flex-col px-4"
            >
              <DsLink
                @click="onClose"
                :data-testid="item.dataTestid"
                :to="item.route"
                :class="[
                  isActive(item) ? 'link-offcanvas-active' : 'link-offcanvas',
                  item.cssClasses,
                ]"
              >
                {{ item.label }}
                <DsComponentRender
                  v-if="item.suffix"
                  :component="item.suffix"
                />
              </DsLink>
            </li>
          </ul>
          <ul class="border-t border-gray-200 mt-4 pt-4">
            <li
              v-for="(item, key) in itemsAccount"
              :key="key"
              class="space-y-2 flex flex-col px-4"
            >
              <DsLink
                v-if="Object.keys(item.route).length > 0"
                @click="onClose"
                :data-testid="item.dataTestid"
                :to="item.route"
                :class="[
                  isActive(item) ? 'link-offcanvas-active' : 'link-offcanvas',
                  item.cssClasses,
                ]"
              >
                {{ item.label }}
              </DsLink>
              <DsButton
                v-else
                theme-display="link"
                @click.prevent="callFunction(item)"
                :data-testid="item.dataTestid"
                class="link-offcanvas text-left"
              >
                {{ item.label }}
              </DsButton>
            </li>
          </ul>
          <!-- /End replace -->
        </div>
        <div class="mobile-menu-sidebar-footer">
          <DsLink :to="{ name: 'Logout' }" class="block link-offcanvas">
            {{ $t("common.route.logout.link") }}
          </DsLink>
        </div>
      </div>
    </template>
  </DsSidebar>
</template>

<script lang="ts">
/**
 * OffCanvas component
 * @version 2.0.0 - 2022-06-1
 */
import { computed, defineComponent } from "vue";
import type { PropType } from "vue";
import type { LocationAsRelativeRaw } from "vue-router";

import { DsComponentRender } from "@devsalsa/vue-core";
//import core components
import { DsAvatar, DsButton, DsLink, DsSidebar } from "@devsalsa/vue-core";

import CloseOutlineIcon from "@/shared/components/icon/CloseOutlineIcon.vue";

import type { NavigationItem } from "@/core/shared/components/Navigation/Navigation.types";
import type { CollaboratorSettings } from "@/modules/settings/services/collaborators/CollaboratorsService.types";

//import core services
import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";

export default defineComponent({
  name: "OffCanvas",
  components: {
    CloseOutlineIcon,
    DsAvatar,
    DsLink,
    DsButton,
    DsSidebar,
    DsComponentRender,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as PropType<NavigationItem[]>,
      required: true,
    },
    itemsAccount: {
      type: Array as PropType<NavigationItem[]>,
      required: true,
    },
  },
  data() {
    return {
      accountInfo: computed(
        () =>
          AccountHandler.accountInfo.value ?? {
            first_name: "",
            last_name: "",
            email: "",
            collaborator: {} as CollaboratorSettings,
            is_collaborator: false,
          }
      ),
    };
  },
  watch: {
    grid(val) {
      this.isShowing = this.isShowing && val;
    },
  },
  computed: {
    grid() {
      return this.$grid("xl:hidden");
    },
    isShowing: {
      get() {
        return this.isOpen;
      },
      set(val: boolean) {
        this.$emit("update:isOpen", val);
      },
    },
    email(): string {
      return `${this.accountInfo.email || ""}`;
    },
    fullName(): string {
      return `${this.accountInfo.first_name || ""} ${
        this.accountInfo.last_name || ""
      }`;
    },
    collaboratorBrand(): string {
      return this.accountInfo.collaborator.allowed_brands[0].name || "";
    },
    userLabel(): string {
      if (this.accountInfo.is_collaborator) {
        return this.collaboratorBrand;
      }
      return this.email;
    },
  },
  methods: {
    onClose() {
      this.isShowing = false;
    },
    isActive(item: NavigationItem): boolean {
      return this.$route.name === (item.route as LocationAsRelativeRaw).name;
    },
    callFunction(item: NavigationItem): void {
      if (item.action && typeof item.action === "function") {
        this.onClose();
        item.action();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.link-offcanvas {
  @apply text-gray-500 text-sm font-medium p-2 no-underline hover:no-underline
  hover:text-gray-900 hover:bg-gray-100 hover:rounded-lg;
  &-active {
    @apply text-sm font-medium py-2 px-2 text-gray-900 bg-gray-100 rounded-lg no-underline hover:no-underline;
  }
}

.mobile-menu-sidebar {
  &-header {
    @apply px-6 border-b border-gray-200 pb-4 z-10 bg-white sticky top-0 pt-6;
  }

  &-body {
    @apply flex h-full flex-col relative;

    :deep(.ds-badge) {
      @apply ml-5 rounded-full;
    }
  }

  &-footer {
    @apply border-t border-gray-200 w-full bg-white bottom-0 p-4 sticky;
  }
}
</style>
